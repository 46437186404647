import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "modern-and-applicative-javascript---part-3"
    }}>{`Modern and applicative JavaScript - Part 3`}</h1>
    <h1 {...{
      "id": "getting-started-with-angular"
    }}>{`Getting started with Angular`}</h1>
    <h5 {...{
      "id": "january-10-2020--aptitude-softwares-office"
    }}>{`January 10, 2020 | `}<a parentName="h5" {...{
        "href": "http://twitter.com/AptitudeSW"
      }}>{`Aptitude Software's`}</a>{` office`}</h5>
    <hr></hr>
    <ul>
      <li parentName="ul">{`📊 `}<strong parentName="li">{`Slides`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://slides.com/kajetansw/getting-started-with-angular"
        }}>{`https://slides.com/kajetansw/getting-started-with-angular`}</a></li>
      <li parentName="ul">{`⌚ `}<strong parentName="li">{`Duration`}</strong>{`: ~90 minutes`}</li>
      <li parentName="ul">{`👨‍💻 `}<strong parentName="li">{`Form`}</strong>{`: workshops`}</li>
    </ul>
    <hr></hr>
    <p>{`This was a third (and last 😔) part of workshops I hosted at my company where we worked on implementing "Splitter" web application together.`}</p>
    <p>{`This time we got into the core of the entire workshop series - implementing front-end of our app in Angular! `}</p>
    <p>{`Sadly this was the end but I was enormously excited to share and almost... "advocate" for this technologies, libraries and frameworks in front of my co-workers from other teams. I hope they left the workshops excited about what's to come as I was happy creating this content 😊`}</p>
    <hr></hr>
    <h3 {...{
      "id": "outline"
    }}>{`Outline`}</h3>
    <ul>
      <li parentName="ul">{`Angular and new trends that came with it`}</li>
      <li parentName="ul">{`Concept of components`}</li>
      <li parentName="ul">{`Angular architecture`}</li>
      <li parentName="ul">{`👨‍💻 CODING TIME 👨‍💻`}</li>
      <li parentName="ul">{`Angular CLI`}</li>
      <li parentName="ul">{`Data binding`}</li>
      <li parentName="ul">{`Structural directives`}</li>
      <li parentName="ul">{`Services and dependency injection`}</li>
      <li parentName="ul">{`Routing`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      